import React from "react"
import { Row, Col } from "react-bootstrap";

const ContactDetail = () => (
 <div>
    <Row className="mt-2">
        <h3>Contact Details</h3>
    </Row>
    <Row className="mt-2">
        <Col xs={3}>Address:</Col>
        <Col xs={9}>8889 University Dr,
        <p>Burnaby, BC V5A1S6</p></Col>
    </Row>
    <Row className="mt-2">
        <Col xs={3}>Phones: </Col>
        <Col xs={9}>(604) 262 - 5242</Col>
    </Row>
    <Row className="mt-2">
        <Col xs={3}>EMail: </Col>
        <Col xs={9}>info@vankutech.com</Col>
    </Row>
    <Row className="mt-2">
        <Col xs={3}>Bus. Hours: </Col>
        <Col xs={9}>09:00AM - 5:00PM</Col>
    </Row>  
  </div>
)

export default ContactDetail