import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap";
import ContactDetail from "../components/contactDetail";
import GetInTouchForm from "../components/getInTouchForm";

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <Container className="mt-5">
      <Row className="mt-3">
        <Col md={6}>
            <ContactDetail></ContactDetail>
        </Col>
        <Col md={6}>
          <GetInTouchForm></GetInTouchForm>
        </Col>
      </Row>
    </Container>
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default ContactPage
