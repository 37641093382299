import React from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelope, faPhone, faComment } from '@fortawesome/free-solid-svg-icons'

export default class GetInTouchForm extends React.Component {
  state = {
    name: "",
    mail: "",
    phone: "",
    message: "",
  };

  handleInputChangeName = event => {
    const target = event.target;
    const value = target.value;
    this.setState({
      name: value,
    });
  };

  handleInputChangeMail = event => {
    const target = event.target;
    const value = target.value;
    this.setState({
      mail: value,
    });
  };

  handleInputChangePhone = event => {
    const target = event.target;
    const value = target.value;
    this.setState({
      phone: value,
    });
  };

  handleInputChangeMessage = event => {
    const target = event.target;
    const value = target.value;
    this.setState({
      message: value,
    });
  };

  handleSubmit = event => {
    /*event.preventDefault();*/
    alert(`Hi ${this.state.name},  Thank you for submitting your info, we will contact you soon!`);
  };

  render() {
    return (<form action="https://formspree.io/info@vankutech.com" method="POST">
      <h3 className="mt-2">Let's get in touch</h3>
          
          <InputGroup size="sm" className="mb-3 mt-4">
            <InputGroup.Prepend>
              <InputGroup.Text id="name" ><FontAwesomeIcon icon={faUser} size="xs" /></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Your name"
              aria-label="name"
              aria-describedby="name"
              name="name" 
              value={this.state.name}
              onChange={this.handleInputChangeName}
            />
          </InputGroup>
  
          <InputGroup size="sm" className="mb-3 mt-2">
            <InputGroup.Prepend>
              <InputGroup.Text id="mail"><FontAwesomeIcon icon={faEnvelope} size="xs" /></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Your e-mail"
              aria-label="email"
              aria-describedby="mail"
              name="mail" 
              value={this.state.mail}
              onChange={this.handleInputChangeMail}
            />
          </InputGroup>
  
          <InputGroup size="sm" className="mb-3 mt-2">
            <InputGroup.Prepend>
              <InputGroup.Text id="phone"><FontAwesomeIcon icon={faPhone} size="xs" /></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Your phone"
              aria-label="phone"
              aria-describedby="basic-addon1"
              name="phone" value={this.state.phone}
              onChange={this.handleInputChangePhone}
            />
          </InputGroup>
  
          <InputGroup size="sm" className="mb-3 mt-2">
            <InputGroup.Prepend>
              <InputGroup.Text id="message"><FontAwesomeIcon icon={faComment} size="xs" /></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              as="textarea"
              placeholder="Your message"
              aria-label="message"
              aria-describedby="message"
              name="message" value={this.state.message}
              onChange={this.handleInputChangeMessage}
            />
          </InputGroup>
          <InputGroup size="sm">
            <Button variant="primary" size="sm" type="submit">Send</Button>
          </InputGroup>
          
    </form>);
  }
}

